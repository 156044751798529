export const FONT_SCORE_STYLE = {
  fontFamily: 'Bango',
  color: '#000',
  fontSize: ' 36px'
};

export const FONT_TIME_STYLE = {
    fontFamily: 'Gotham',
    color: '#000',
    fontSize: '28px'
};

export const FONT_USERNAME_STYLE = {
    fontFamily: 'Bango',
    color: '#000',
    fontSize: '25px'
};

export const FONT_IDUSER_STYLE = {
  fontFamily: 'Gotham',
  color: '#000',
  fontSize: '24px'
};

export const FONT_IDTEXT_STYLE = {
  fontFamily: 'Bango',
  color: '#000',
  fontSize: '24px'
};

export const FONT_LEVEL_STYLE = {
  fontFamily: 'Bango',
  color: '#000',
  fontSize: '25px'
};
export const FONT_INDEX_QUESTION_STYLE =  {
  fontFamily: 'Bango',
  fontSize: '50px',
  color: '#fff',
  stroke: '#106BC7',
  strokeThickness: 10
};

export const FONT_INSTRUCTION_DETAIL_STYLE = {
  font: '24px Bango',
  color: '#fff',
  stroke: '#106BC7',
  strokeThickness: 5,
};

export const FONT_INSTRUCTION_TITLE_STYLE = {
  font: 'bold 50px Bango',
  color: '#fff',
  stroke: '#106BC7',
  strokeThickness: 10,
};

export const FONT_RESULT_STYLE = {
  font: 'bold 40px Bango',
  color: '#fff',
  stroke: '#106BC7',
  strokeThickness: 10,
};

export const FONT_QUESTION_STYLE = {
  fontFamily: 'Bango',
  fontSize: '28px',
  color: '#fff',
  stroke: '#000',
  strokeThickness: 6,
  align: 'center',
};

export const FONT_TEXT_ERROR_STYLE = {
  font: 'bold 48px Gotham',
  color: '#106BC7',
  // stroke: '#106BC7',
  // strokeThickness: 10,
};
export const FONT_TEXT_SCORE_STYLE = {
  font: 'bold 65px Bango',
  color: '#fff',
  stroke: '#106BC7',
  strokeThickness: 10,
};
