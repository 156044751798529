import { FONT_INSTRUCTION_TITLE_STYLE, FONT_TEXT_ERROR_STYLE } from '../constants/font';
import { TEXT_ERROR_DETAIL } from '../constants/text';
import { btnClick } from './../helper/utils';

export default class ErrorDialog extends Phaser.GameObjects.Group {
  private textErr: Phaser.GameObjects.Text;
  private box: Phaser.GameObjects.Container;
  constructor(scene: Phaser.Scene) {
    super(scene);
    this.setDepth(1);
    const { width, height } = scene.sys.canvas;
    const overlay = scene.add.rectangle(0, 0, width, height, 0x000, 0.7).setOrigin(0, 0);

    this.box = scene.add.container(width/2, height/2);
    const bg = scene.add.image(0, 0, 'result-board');
    const oppTitle = scene.add.text(0, -320, 'OPP', FONT_INSTRUCTION_TITLE_STYLE).setOrigin(0.5, 0);
    this.textErr = scene.add
      .text(0, 0, TEXT_ERROR_DETAIL, FONT_TEXT_ERROR_STYLE)
      .setOrigin(0.5, 0.5)
      .setWordWrapWidth(600)
      .setLineSpacing(20)
      .setAlign('center');
    const btnOk = scene.add
      .image(0, 350, 'button')
      .setOrigin(0.5, 0.5)
      .setInteractive({ cursor: 'pointer' });
    const textOK = scene.add.text(0, 350, 'OK', FONT_INSTRUCTION_TITLE_STYLE).setOrigin(0.5, 0.5);
    btnClick(
      scene,
      btnOk,
      () => {
        this.scene.sound.get('Sound_TapButton').play();
        this.setVisible(false);
      },
      0.9,
      1
    );

    this.add(overlay);
    this.box.add(bg);
    this.box.add(oppTitle);
    this.box.add(this.textErr);
    this.box.add(btnOk);
    this.box.add(textOK);
    this.add(this.box);
    this.setVisible(false);
  }

  show({ text = TEXT_ERROR_DETAIL }) {
    this.setVisible(true);
    this.textErr.setText(text);
    this.box.scale = 0.5;
    this.scene.tweens.add({
      targets: this.box,
      scale: 1,
      duration: 500,
      ease: 'Back',
      yoyo: false,
      repeat: 0,
    });
  }

  close() {
    this.setVisible(false);
  }
}
