import { btnHover } from '../helper/utils';

export default class Control extends Phaser.GameObjects.Group {
  constructor(scene: Phaser.Scene, music: Phaser.Sound.BaseSound, onHandleClose?: () => void) {
    super(scene);
    const { width } = scene.sys.canvas;
    // const bgMain = scene.add.sprite(0, 0, 'bg2');

    const btnSoundOn = scene.add.image(width - 200, 50, 'sound-on').setInteractive({ cursor: 'pointer' });
    const btnSoundOff = scene.add.image(width - 200, 50, 'sound-off').setInteractive({ cursor: 'pointer' });
    if (music.isPlaying) {
      btnSoundOff.setVisible(false);
    } else {
      btnSoundOn.setVisible(false);
    }
    btnHover(scene, btnSoundOff);
    btnHover(scene, btnSoundOn);
    btnSoundOn.on('pointerup', () => {
      music.pause();
      btnSoundOn.setVisible(false);
      btnSoundOff.setVisible(true);
    });
    btnSoundOff.on('pointerup', () => {
      music.resume();
      btnSoundOff.setVisible(false);
      btnSoundOn.setVisible(true);
    });
    const btnClose = scene.add.image(width - 100, 50, 'close').setInteractive({ cursor: 'pointer' });
    btnClose.on('pointerup', () => {
      scene.sound.removeAll();
      onHandleClose && onHandleClose();
      scene.scene.start('Home');
    });
  }
}
