import axios from 'axios';
import { ACCESS_TOKEN_KEY, BASE_API_URL } from './config';
export const axiosClient = axios.create({
  baseURL: BASE_API_URL,
});

axiosClient.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const customHeaders = accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {};

    return {
      ...config,
      headers: {
        ...customHeaders, // auto attach token
        ...config.headers, // but you can override for some requests
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return error;
  }
);

export default axiosClient;
