import { FONT_INSTRUCTION_DETAIL_STYLE, FONT_INSTRUCTION_TITLE_STYLE } from '../constants/font';
import { TEXT_INSTRUCTION } from '../constants/text';
import { btnClick, btnClickZoomUp } from '../helper/utils';
import { NetworkManager } from '../network/NetworkManager';
import Control from '../ui/Control';

export default class HomeScene extends Phaser.Scene {
  networkManager: NetworkManager;

  constructor() {
    super('Home');
  }

  async create() {
    // const bgMain = this.add.sprite(0, 0, 'bg2');

    this.networkManager = NetworkManager.getInstance();
    await this.networkManager.setInfo({});
    await this.networkManager.getInfo();

    const { width, height } = this.sys.canvas;
    const music = this.sound.add('Music_Gameplay', {
      volume: 1,
      loop: true,
    });

    const sfxTapButton = this.sound.add('Sound_TapButton', {
      volume: 0.8,
    });

    this.sound.add('Sound_GameLose');
    this.sound.add('Sound_GameWin');

    music.play();
    this.add.image(width * 0.5, height * 0.5, 'BG');
    this.add.image(width * 0.5, 300, 'fish').setOrigin(0.5, 0.5);
    this.add.spine(420, 620, 'Octopus', 'Idle', true);
    const control = new Control(this, music);
    control.setDepth(1);
    this.add.image(width * 0.5, height * 0.5, 'instruction-board').setOrigin(0.5, 0.5);
    this.add.text(width * 0.5, 150, 'INSTRUCTIONS', FONT_INSTRUCTION_TITLE_STYLE).setOrigin(0.5, 0);
    this.add
      .text(width * 0.5, height * 0.5 + 20, TEXT_INSTRUCTION, FONT_INSTRUCTION_DETAIL_STYLE)
      .setOrigin(0.5, 0.5)
      .setWordWrapWidth(600)
      .setLineSpacing(5);

    const btnStart = this.add.image(width - 200, height - 150, 'start-button').setInteractive({ cursor: 'pointer' });

    btnClick(this, btnStart, () => {
      sfxTapButton.play();
      this.game.scene.stop('Home');
      this.game.scene.start('Game');
    });

    btnStart.on(
      'clicked',
      () => {
        sfxTapButton.play();
        this.game.scene.stop('Home');
        this.game.scene.start('Game');
      },
      this
    );

    this.tweens.add({
      targets: btnStart,
      scale: 1.1,
      yoyo: true,
      duration: 400,
      repeat: -1,
    });
  }

  onEnterGameClick() {
    this.game.scene.start('Login');
  }

  onEnterHelpClick() {
    this.game.scene.start('Help');
  }
}
