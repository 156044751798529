import 'phaser';
import 'phaser/plugins/spine/dist/SpinePlugin';
import BootScene from './scenes/Boot';
import GameScene from './scenes/Game';
import HomeScene from './scenes/Home';
import SplashScene from './scenes/Splash';
import ResultScene from './scenes/Result';

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  backgroundColor: '#000',
  scale: {
    mode: Phaser.Scale.FIT,
    parent: 'game',
    width: 1920,
    height: 1080,
  },
  scene: [BootScene, HomeScene, SplashScene, GameScene, ResultScene],
  width: 1920,
  height: 1080,
  plugins: {
    scene: [
      { key: 'SpinePlugin', plugin: window.SpinePlugin, mapping: 'spine' },
    ],
  },
};

const game = new Phaser.Game(config);
