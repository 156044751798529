import { IStartGameParams } from './../models/param';
import { axiosClient } from './axios';
import { ICheckAnserParams, IFinishGameParams, IGetInfoParams, ISetInfoParams } from '../models/param';
import { IResponseGame } from '../models/response';

export const  GameService = {

  setInfo(params: ISetInfoParams) {
    return axiosClient.post<IResponseGame>(
      '/v2/demogame/setinfo',
      params
    );
  },

  getInfo(params: IGetInfoParams){
    return axiosClient.post<IResponseGame>(
      '/v2/demogame/getinfo',
      params
    );
  },

  startGame(params: IStartGameParams){
    return axiosClient.post<IResponseGame>(
      '/v2/demogame/startgame',
      params
    );
  },

  checkAnswer(params: ICheckAnserParams){
    return axiosClient.post<IResponseGame>(
      '/v2/demogame/answercheck',
      params
    );
  },

  finishGame(params: IFinishGameParams){
    return axiosClient.post<IResponseGame>(
      '/v2/demogame/finishgame',
      params
    );
  }

};
