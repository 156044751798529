export const imagesList = {
  'BG': 'images/bg/BG.png',
  'answer-button': 'images/button/answer-button.png',
  'button': 'images/button/button.png',
  'close': 'images/button/close.png',
  'sound-off': 'images/button/sound-off.png',
  'sound-on': 'images/button/sound-on.png',
  'start-button': 'images/button/start-button.png',
  'instruction-board': 'images/dialog/instruction-board.png',
  'result-board': 'images/dialog/result-board.png',
  'score': 'images/dialog/score.png',
  'fish': 'images/item/fish.png',
  'pearl': 'images/item/pearl.png',
  'score01': 'images/item/score01.png',
  'score02': 'images/item/score02.png',
  'wood': 'images/item/wood.png',
  'wood-mask': 'images/item/wood-mask.png',
  'loading-bar': 'images/ui/loading-bar.png',
};

export const audiosList = {
  'Music_Gameplay': ['audio/Music_Gameplay.ogg', 'audio/Music_Gameplay.mp3'],
  'Sound_GameLose': ['audio/Sound_GameLose.wav', 'audio/Sound_GameLose.mp3'],
  'Sound_GameWin': ['audio/Sound_GameWin.wav', 'audio/Sound_GameWin.mp3'],
  'Sound_TapButton': ['audio/Sound_TapButton.mp3']
};

export const spinesList = {
  'Octopus': {
    json: 'Anim/Octopus/Octopus.json',
    atlas: ['Anim/Octopus/Octopus.atlas.txt']
  },
  'SeaShell': {
    json: 'Anim/SeaShell/SeaShell.json',
    atlas: ['Anim/SeaShell/SeaShell.atlas.txt']
  },
};
