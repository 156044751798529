const START_POS_X = 530;
const QUESTION_SPACE_X = 230;
const BLOCK_SPACE_X = 230;
// const BLOCK_SPACE_Y = 150;

export const questionsPos = [];
for (let index = 0; index < 5; index++) {
  const element = new Phaser.Math.Vector2(START_POS_X + index * QUESTION_SPACE_X, 250);
  const elementLow = new Phaser.Math.Vector2(START_POS_X + index * QUESTION_SPACE_X, 350);
  questionsPos[index] = element;
  questionsPos[index + 5] = elementLow;
}

export const blocksPos = [];
for (let index = 0; index < 5; index++) {
  const element = new Phaser.Math.Vector2(START_POS_X + index * BLOCK_SPACE_X, 800);
  const elementLow = new Phaser.Math.Vector2(START_POS_X + index * BLOCK_SPACE_X, 950);
  blocksPos[index] = element;
  blocksPos[index + 5] = elementLow;
}
