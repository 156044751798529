import { BoxScore } from '../entity/BoxScore';
import QuestionBlock from '../entity/QuestionBlock';
import { blocksPos, questionsPos } from '../helper/constants';
import { btnClick, btnClickZoomUp } from '../helper/utils';
import { NetworkManager } from '../network/NetworkManager';
import Control from '../ui/Control';
import ErrorDialog from '../ui/ErrorDialog';
import { FONT_TEXT_SCORE_STYLE } from './../constants/font';
import { convertSecondToHour } from './../helper/utils';

export default class GameScene extends Phaser.Scene {
  constructor() {
    super('Game');
  }
  private boxScore: BoxScore;
  private emitter: Phaser.Events.EventEmitter;
  private showOpp: ErrorDialog = null;
  private octopus: SpineGameObject = null;
  private seaShell: SpineGameObject = null;
  private score: number = 0;
  private width: number = 0;
  private height: number = 0;
  private answerButton: Phaser.GameObjects.Sprite = null;
  private networkManager: NetworkManager;
  private currentQuestionIndex = 0;
  private totalQuestion = 10;
  private emptyIndex = 0;
  private answerList = [];
  private pearl: Phaser.GameObjects.Image = null;
  private textScore: Phaser.GameObjects.Text = null;
  protected answerLayer: Phaser.GameObjects.Layer;
  protected questionLayer: Phaser.GameObjects.Layer;

  async create() {
    const { width, height } = this.sys.canvas;
    this.width = width;
    this.height = height;
    this.networkManager = NetworkManager.getInstance();
    await this.networkManager.startGame();
    // this.on('')

    this.add.image(0, 0, 'BG').setOrigin(0, 0);
    this.add.image(width * 0.5, 300, 'fish').setOrigin(0.5, 0.5);
    const control = new Control(this, this.sound.get('Music_Gameplay'), () => this.reset());
    control.setDepth(1);
    this.octopus = this.add.spine(350, 620, 'Octopus', 'Idle', true);
    this.seaShell = this.add.spine(width / 2, 700, 'SeaShell', 'Idle', true);
    this.pearl = this.add.image(width * 0.5, height * 0.5 + 51, 'pearl').setOrigin(0.5, 0.5);
    this.textScore = this.add.text(350, 550, '+10', FONT_TEXT_SCORE_STYLE).setOrigin(0.5, 0.5);
    this.textScore.setVisible(false);
    this.pearl.setVisible(false);
    this.seaShell.on('complete', this.processSeaShellOpen, this);
    this.totalQuestion = this.networkManager.totalQuestion;
    this.boxScore = new BoxScore(this, {
      level: this.networkManager.level,
      idUser: this.networkManager.user.AccountId.toString(),
      userName: this.networkManager.user.AccountName,
      indexQuestion: 0,
      totalQuestion: this.networkManager.totalQuestion,
      totalTime: this.networkManager.examTime,
    });

    this.events.on(
      'timeout',
      () => {
        this.gameOver();
      },
      this
    );
    this.input.on(
      'gameobjectup',
      function (pointer, gameObject) {
        gameObject.emit('clicked', gameObject);
      },
      this
    );

    this.answerButton = this.add.sprite(1750, 850, 'answer-button').setInteractive({ cursor: 'pointer' });
    // btnClick(this, this.answerButton);
    btnClickZoomUp(this, this.answerButton);
    this.answerButton.on('clicked', this.onSubmitAnswer, this);
    this.answerLayer = this.add.layer();
    this.questionLayer = this.add.layer();
    this.setupGame();
    this.loadQuestion();
    this.showOpp = new ErrorDialog(this);
  }

  setupGame() {
    questionsPos.forEach((vec2) => {
      const questionBlock = this.add.sprite(vec2.x, vec2.y, 'wood-mask');
      this.answerLayer.add(questionBlock);
    });
    blocksPos.forEach((pos) => {
      const block = new QuestionBlock(this);
      block.setGroupPosition(pos);
      block.startPos = pos;
      block.on('clicked', this.onClickedAnswer, this);
      this.questionLayer.add(block);
    });
  }

  loadQuestion() {
    this.clearLastQuestion();
    this.processLoadQuestion();
  }

  processLoadQuestion() {
    const { question } = this.networkManager;
    const { ans } = question[this.currentQuestionIndex];
    this.octopus.setAnimation(0, 'Idle', true);
    this.seaShell.setAnimation(0, 'Idle', true);
    this.answerButton.setVisible(true);
    ans.forEach((data, i) => {
      const { content } = data;
      const ansBgImage = this.answerLayer.getAt(i) as Phaser.GameObjects.Image;
      ansBgImage.alpha = 1;
      const questionBlock = this.questionLayer.getAt(i) as QuestionBlock;
      questionBlock.alpha = 1;
      questionBlock.active = true;
      questionBlock.setText(content);
    });
    this.boxScore.updateIndexQuestion(this.currentQuestionIndex + 1, this.totalQuestion);
  }

  onClickedAnswer(block: QuestionBlock) {
    this.sound.get('Sound_TapButton').play();
    // console.log('clicked block', block.answerIndex, this.emptyIndex);
    if (block.answerIndex === -1) {
      const pos = questionsPos[this.emptyIndex];
      block.setGroupPosition(pos);
      block.answerIndex = this.emptyIndex;
      this.answerList[this.emptyIndex] = block;
    } else {
      this.answerList[block.answerIndex] = undefined;
      block.resetPosition();
    }
    this.calculateEmptyIndex();
  }

  calculateEmptyIndex() {
    this.emptyIndex = 0;
    for (let index = 0; index < this.answerList.length; index++) {
      const element = this.answerList[index];
      // console.log(index, element);
      if (!element) {
        this.emptyIndex = index;
        return;
      }
      this.emptyIndex++;
    }
  }

  async onSubmitAnswer() {
    const { question } = this.networkManager;
    const currentQuestion = question[this.currentQuestionIndex];
    const { ans } = currentQuestion;
    ans.sort((a, b) => a.orderTrue - b.orderTrue);
    if (this.answerList.filter((block) => !!block).length < ans.length) {
      this.showOpp.show({});
      return;
    }
    // const resultTmp = ans.map((item) => item.content).join(' ');
    // const result = this.answerList.map((block) => block.getContent()).join(' ');
    const answerString = this.answerList
      .map((block) => block.getContent())
      .join(' ')
      .toString()
      .split('')
      .join('|');
    const payloadAnswer = {
      questId: currentQuestion.id,
      ans: answerString,
      point: currentQuestion.Point,
    };
    const result = await this.networkManager.checkAnswer(payloadAnswer);
    if (result?.point) {
      this.sound.get('Sound_GameWin').play();
      this.octopus.setAnimation(0, 'Fun', true);
      this.seaShell.setAnimation(0, 'Open', false);
    } else {
      this.sound.get('Sound_GameLose').play();
      this.octopus.setAnimation(0, 'Sad', true);
      this.time.addEvent({
        delay: 1500,
        callback: () => {
          this.processLoadQuestion();
        },
        loop: false,
      });
    }
    this.answerButton.setVisible(false);
    this.currentQuestionIndex++;
    if (this.currentQuestionIndex >= this.totalQuestion) {
      this.gameOver();
    } else {
      this.clearLastQuestion();
    }
    return true;
  }

  processSeaShellOpen(e) {
    const { name } = e.animation;
    if (name === 'Open') {
      this.seaShell.setAnimation(0, 'Idle', true);
      this.pearl.setVisible(true);
      this.tweens.add({
        targets: this.pearl,
        x: 350,
        y: 620,
        yoyo: false,
        duration: 1000,
        repeat: 0,
        callbackScope: this,
        onComplete: this.processCompleteSeaShellOpen,
      });
    }
  }

  processCompleteSeaShellOpen() {
    this.pearl.setVisible(false);
    this.pearl.setX(this.width * 0.5);
    this.pearl.setY(this.height * 0.5 + 51);
    this.processShowScore();
  }

  processShowScore() {
    this.textScore.setVisible(true);
    this.score += 10;
    this.boxScore.updateScore(this.score);
    this.tweens.add({
      targets: this.textScore,
      y: 370,
      yoyo: false,
      duration: 700,
      repeat: 0,
      callbackScope: this,
      onComplete: this.processCompleteShowScore,
    });
  }

  processCompleteShowScore() {
    this.textScore.setVisible(false);
    this.textScore.setY(620);
    this.processLoadQuestion();
  }

  clearLastQuestion() {
    this.answerList = [];
    this.calculateEmptyIndex();
    this.answerLayer.getChildren().forEach((child: Phaser.GameObjects.Image) => {
      child.alpha = 0;
    });
    this.questionLayer.getChildren().forEach((child: QuestionBlock) => {
      child.alpha = 0;
      child.active = false;
      child.resetPosition();
    });
  }

  async gameOver() {
    this.input.off('gameobjectup');
    const dataResult = await this.networkManager.finishGame();
    this.reset();
    return this.scene.start('Result', { score: dataResult.point, time: convertSecondToHour(+dataResult.time) });
  }

  reset() {
    this.clearLastQuestion();
    this.currentQuestionIndex = 0;
  }
}
