import { FONT_RESULT_STYLE } from '../constants/font';
import Control from '../ui/Control';

export type DataProps = { score: number; time: string };
export default class Result extends Phaser.Scene {
  data = null;
  constructor() {
    super('Result');
  }
  init(data: DataProps) {
    this.data = data;
  }
  create() {
    const { width, height } = this.sys.canvas;

    this.add.image(width * 0.5, height * 0.5, 'BG');

    const control = new Control(this, this.sound.get('Music_Gameplay'));
    control.setDepth(1);
    this.add.spine(400, 600, 'Octopus', 'Fun', true);
    this.add.image(width * 0.5, height * 0.5, 'result-board');
    // this.data.control.setDepth(1);
    this.add
      .text(width * 0.5, height * 0.5 - 80, `SCORE: ${this.data.score}`, FONT_RESULT_STYLE)
      .setOrigin(0.5, 0.5);

    this.add
      .text(width * 0.5, height * 0.5 + 10, `TIME: ${this.data.time}`, FONT_RESULT_STYLE)
      .setOrigin(0.5, 0.5);
  }
}
