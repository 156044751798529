import 'phaser';

export default class BootScene extends Phaser.Scene {
  constructor() {
    super('Boot');
  }

  init() {
    //  Inject our CSS
    const element = document.createElement('style');
    document.head.appendChild(element);
    const sheet = element.sheet;
    let styles = '@font-face { font-family: "Gotham"; src: url("assets/font/Gotham-Medium.otf") format("opentype"); }\n';
    sheet.insertRule(styles, 0);
    styles = '@font-face { font-family: "Bango"; src: url("assets/font/SVN-BANGO.OTF") format("opentype"); }';
    sheet.insertRule(styles, 0);
  }

  preload() {
    this.load.script('webfont', 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');
    this.load.image('loaderBar', 'assets/images/ui/loading-bar.png');
    this.load.image('splash', 'assets/images/bg/splash.png');
  }

  create() {
    window.WebFont.load({
      custom: {
        families: ['Gotham', 'Bango']
      },
      active: () => {
        this.scene.start('Splash');
      }
    });
  }

}
