import { audiosList, imagesList, spinesList } from '../res';

export default class SplashScene extends Phaser.Scene {
  constructor() {
    super({
      key: 'Splash',
    });
  }

  loaderBar: Phaser.GameObjects.Sprite;

  preload() {
    this.add.image(0, 0, 'splash').setOrigin(0, 0).setScale(1.2);
    const { width, height } = this.game.context.canvas;
    const progress = this.add.sprite(width * 0.5, height * 0.8, 'loaderBar');
    this.load.on('progress', function (value) {
      progress.setCrop(0, 0, progress.width * value, progress.height);
    });
    this.load.on('complete', function () {
      progress.destroy();
    });
    this.load.setPath('assets/');
    // load images
    Object.keys(imagesList).forEach((imageKey) => {
      this.load.image(imageKey, imagesList[imageKey]);
    });
    // load audios
    Object.keys(audiosList).forEach((audioKey) => {
      // console.log('load audio ', audioKey);
      this.load.audio(audioKey, audiosList[audioKey]);
    });
    Object.keys(spinesList).forEach((spineKey) => {
      this.load.spine(spineKey, spinesList[spineKey].json, spinesList[spineKey].atlas);
    });
  }

  create() {
    setTimeout(() => {
      this.scene.start('Home');
    }, 200);
  }
}
