import { FONT_QUESTION_STYLE } from '../constants/font';
import { btnClick, btnClickZoomUp } from '../helper/utils';

export default class QuestionBlock extends Phaser.GameObjects.Container {
  constructor(scene: Phaser.Scene) {
    super(scene);
    const block = scene.add.sprite(0, 0, 'wood');
    block.setInteractive();
    block.on('clicked', this.clickHandler, this);
    this.add(block);
    this.label = scene.add
      .text(0, 0, '', FONT_QUESTION_STYLE)
      .setWordWrapWidth(200)
      .setAlign('center');
    this.label.setOrigin(0.5, 0.5);
    this.add(this.label);
    btnClickZoomUp(this.scene, block, this, 1.3, 230);
  }

  private label: Phaser.GameObjects.Text;
  public startPos: Phaser.Math.Vector2;
  public answerIndex: number = -1;

  resetPosition() {
    this.answerIndex = -1;
    this.setGroupPosition(this.startPos);
  }

  setText(text: string) {
    this.label.setText(text);
  }

  getContent() {
    return this.label.text;
  }

  setGroupPosition(vec2: Phaser.Math.Vector2) {
    this.setPosition(vec2.x, vec2.y);
  }

  clickHandler(box) {
    this.emit('clicked', this);
  }
}
