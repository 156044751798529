import { genArrayPosCenter } from './../helper/utils';
import { Scene, GameObjects, Time, Physics } from 'phaser';
import { GAME_WIDTH } from '../constants/size';
import { convertSecondToHour } from '../helper/utils';
import {
  FONT_IDTEXT_STYLE,
  FONT_IDUSER_STYLE,
  FONT_INDEX_QUESTION_STYLE,
  FONT_LEVEL_STYLE,
  FONT_SCORE_STYLE,
  FONT_TIME_STYLE,
  FONT_USERNAME_STYLE,
} from '../constants/font';
interface IDataBoxScore {
  level: number;
  idUser: string;
  userName: string;
  indexQuestion: number;
  totalQuestion: number;
  totalTime: number;
}

export class BoxScore extends Phaser.GameObjects.Group {
  scores: Physics.Arcade.StaticGroup;
  textIndexQuestion: GameObjects.Text;
  textTime: GameObjects.Text = null;
  textScore: GameObjects.Text = null;
  totalQuestion = 0;
  cbTimeOut: () => void;
  totalTime = 20 * 60;
  timeStart: integer;
  timeRemain: integer;
  eventUpdateTime: Time.TimerEvent;

  constructor(scene: Scene, data: IDataBoxScore) {
    super(scene);
    const { level = 0, idUser = '123456789', userName = 'Ftech gammer', indexQuestion = 0, totalQuestion = 20, totalTime = 20 * 60 } = data;
    this.totalTime = totalTime;
    this.totalQuestion = totalQuestion;

    this.textIndexQuestion = scene.add
      .text(GAME_WIDTH / 2, 30, `Question ${indexQuestion}/${totalQuestion}`, FONT_INDEX_QUESTION_STYLE)
      .setOrigin(0.5, 0);

    const boxScore = scene.add.image(GAME_WIDTH - 30, 140, 'score').setOrigin(1, 0);

    this.textScore = scene.add
      .text(boxScore.x - boxScore.displayWidth / 2, boxScore.y + boxScore.displayHeight * 0.18, 'Score: 0', FONT_SCORE_STYLE)
      .setOrigin(0.5, 0.5);

    this.textTime = scene.add
      .text(
        boxScore.x - boxScore.displayWidth / 2 + 32,
        boxScore.y + boxScore.displayHeight * 0.33,
        convertSecondToHour(totalTime),
        FONT_TIME_STYLE
      )
      .setOrigin(0.5, 0.5);

    const userNameText = scene.add
      .text(boxScore.x - boxScore.displayWidth / 2, boxScore.y + boxScore.displayHeight * 0.47, userName, FONT_USERNAME_STYLE)
      .setOrigin(0.5, 0.5)
      .setFill('#000');

    const idUserText = scene.add
      .text(boxScore.x - boxScore.displayWidth / 2, boxScore.y + boxScore.displayHeight * 0.58, `     ${idUser}`, FONT_IDUSER_STYLE)
      .setOrigin(0.5, 0.5);

    scene.add
      .text(idUserText.x - idUserText.displayWidth / 2, boxScore.y + boxScore.displayHeight * 0.58, 'ID:', FONT_IDTEXT_STYLE)
      .setOrigin(0, 0.5)
      .setFill('#000');

    const levelText = scene.add
      .text(boxScore.x - boxScore.displayWidth / 2, boxScore.y + boxScore.displayHeight * 0.72, `Level ${level}`, FONT_LEVEL_STYLE)
      .setOrigin(0.5, 0.5);

    const arrayPostLevelItem = genArrayPosCenter({
      width: 51,
      padding: 5,
      count: 4,
      y: boxScore.y + boxScore.displayHeight * 0.83,
      centerWidthPos: boxScore.x - boxScore.displayWidth / 2,
    });

    arrayPostLevelItem.forEach(({ x, y }, index) => {
      if (index < level) scene.add.image(x, y, 'score01');
      else scene.add.image(x, y, 'score02');
    });

    this.add(boxScore);
    this.add(this.textTime);
    this.add(this.textScore);
    this.add(userNameText);
    this.add(idUserText);
    this.add(levelText);

    this.startTime();
  }

  updateScore(newScore: number) {
    this.textScore.setText(`Score: ${newScore}`);
  }

  updateTime(seconds) {
    this.textTime.setText(convertSecondToHour(seconds));
  }

  updateIndexQuestion(indexQuestion, totalQuestion = null) {
    this.textIndexQuestion.setText(`Question ${indexQuestion}/${totalQuestion || this.totalQuestion}`);
  }

  startTime() {
    this.timeStart = new Date().getTime();
    this.eventUpdateTime = this.scene.time.addEvent({
      delay: 500,
      callback: this.updateClock,
      callbackScope: this,
      loop: true,
    });
  }

  updateClock() {
    this.timeRemain = Math.floor(this.totalTime - (new Date().getTime() - this.timeStart) / 1000);
    if (this.timeRemain < 0) {
      this.eventUpdateTime.remove();
      this.scene.events.emit('timeout');
      return;
    }
    this.updateTime(this.timeRemain);
  }

  stopClock() {
    this.eventUpdateTime.remove();
  }
}
