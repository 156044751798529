import { IAnswer, IQuestionType3, IUserInfo } from './../models/index';
import { GameService } from './GameService';

const API_KEY = 'gameIoe';
const SERVICE_CODE = 'IOE';
const QUESTION_TYPE = 5;
const REDIRECT_URL = 'http://localhost:8080';
const URL_GAME = 'http://localhost:8080';
const TOKEN_EXAMPLE =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2VkdS5nby52biIsInN1YiI6IndlYmNsaWVudCIsImF1ZCI6ImFjY2Vzc190b2tlbiIsImV4cCI6MTY2NjY4MDg3MCwic2lkIjowLCJhdGsiOiIiLCJhdHkiOjAsInVpZCI6MTI5NjcyMjg3MSwibmFtZSI6InRraHNmdGVjaCIsImR2SWQiOiJjYzAwMGI2Ni1hYTMyLTRjNGItYjRkYi1lNmFhOGQ1MmZmZmQiLCJvcyI6bnVsbCwiaXAiOiIxMC4yMi4yMi4yNDUiLCJJc0F1dGhlbnRpY2F0ZWQiOnRydWV9.yAUAMTwjIJEDnw3-_KQUgYOTLQvc7MHoBIceyT3Oun8';

export class NetworkManager {
  user: IUserInfo;
  api_key = API_KEY;
  serviceCode = SERVICE_CODE;
  questType = QUESTION_TYPE;
  redirectUrl = REDIRECT_URL;
  urlgame = URL_GAME;
  token_full: string;
  token_md5: string;
  examKey: string;
  gameId: number;
  IPClient: string;
  deviceId: string;
  userInfo: IUserInfo;
  level: number;
  round: number;
  question: Array<IQuestionType3>;
  examTime: number;
  totalQuestion: number;
  listAnswer: Array<IAnswer> = [];

  private static instance: NetworkManager;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  public static getInstance(): NetworkManager {
    if (!NetworkManager.instance) {
      NetworkManager.instance = new NetworkManager();
    }
    return NetworkManager.instance;
  }

  async setInfo(options) {
    const { token = TOKEN_EXAMPLE } = options;
    const response = await GameService.setInfo({
      api_key: this.api_key,
      serviceCode: this.serviceCode,
      questType: this.questType,
      IPClient: this.IPClient,
      urlgame: this.urlgame,
      gameId: this.gameId,
      deviceId: this.deviceId,
      redirectUrl: this.redirectUrl,
      token: token,
    });
    const { code, data, message } = response.data;
    if (code == 0) {
      this.examKey = data?.examKey;
      const url = new URL(data?.urlgame);
      this.token_md5 = url.searchParams.get('token');
      this.level = parseInt(url.searchParams.get('level'));
      this.round = parseInt(url.searchParams.get('round'));
    }
    else {
      return Promise.reject(message);
    }
  }

  async getInfo() {
    if (!this.token_md5) return;
    const response = await GameService.getInfo({
      api_key: this.api_key,
      serviceCode: this.serviceCode,
      gameId: this.gameId,
      IPClient: this.IPClient,
      deviceId: this.deviceId,
      redirectUrl: this.redirectUrl,
      token: this.token_md5,
    });
    const { code, data , message} = response.data;
    if (code == 0 && data) {
      this.examKey = data?.game?.examKey;
      this.question = data?.game?.question;
      this.examTime = data?.examTime;
      this.user = data?.user;
      this.token_full = data?.token;
      this.totalQuestion = this.question?.length;
    }
    else {
      return Promise.reject(message);
    }
  }

  async startGame() {
    const response = await GameService.startGame({
      api_key: this.api_key,
      serviceCode: this.serviceCode,
      examKey: this.examKey,
      gameId: this.gameId,
      IPClient: this.IPClient,
      deviceId: this.deviceId,
      redirectUrl: this.redirectUrl,
      token: this.token_full,
    });
    return response;
  }

  async checkAnswer(answer: IAnswer) {
    this.listAnswer.push(answer);
    const response = await GameService.checkAnswer({
      api_key: this.api_key,
      serviceCode: this.serviceCode,
      examKey: this.examKey,
      ans: answer,
      gameId: this.gameId,
      token: this.token_full,
      IPClient: this.IPClient,
      deviceId: this.deviceId,
      redirectUrl: this.redirectUrl,
    });
    return response?.data?.data;

  }

  async finishGame() {
    const response = await GameService.finishGame({
      api_key: this.api_key,
      serviceCode: this.serviceCode,
      examKey: this.examKey,
      ans: this.listAnswer,
      gameId: this.gameId,
      token: this.token_full,
      IPClient: this.IPClient,
      deviceId: this.deviceId,
      redirectUrl: this.redirectUrl,
    });
    return response?.data?.data;

  }
}
