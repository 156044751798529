/* eslint-disable @typescript-eslint/no-empty-function */
const tweensScale =(
  scene: Phaser.Scene,
  gameObjet: Phaser.GameObjects.Image,
  scale = 1.2,
  duration = 200)=>{
    scene.tweens.add({
      targets: gameObjet,
      scale: scale,
      duration: duration,
      ease: 'Linear',
      yoyo: false,
      repeat: 0,
      callbackScope: scene,
    });
};

export const btnHover = (
  scene: Phaser.Scene,
  gameObjet: Phaser.GameObjects.Image,
  scale = 1.2,
  duration = 200
) => {
  gameObjet.on('pointerover', () => {
    tweensScale(scene, gameObjet, scale, duration);
  });

  gameObjet.on('pointerout', () => {
    tweensScale(scene, gameObjet, 1, duration);
  });
};

export const btnClick = (
  scene: Phaser.Scene,
  gameObjet: Phaser.GameObjects.Image,
  callback = () => {},
  scaleDown = 1.1,
  scaleUp = 1.2,
  duration = 200
) => {
  gameObjet.on('pointerdown', () => {
    tweensScale(scene, gameObjet, scaleDown, duration);
  });

  gameObjet.on('pointerup', () => {
    tweensScale(scene, gameObjet, scaleUp, duration);
    callback();
  });
};

export const btnClickZoomUp = (
  scene: Phaser.Scene,
  gameObjet,
  target?,
  scale = 1.2,
  duration = 200
) => {
  gameObjet.on('pointerdown', () => {
    tweensScale(scene, target || gameObjet, scale, duration);
  });

  gameObjet.on('pointerup', () => {
    tweensScale(scene, target || gameObjet, 1, duration);
  });

  gameObjet.on('pointerout', () => {
    tweensScale(scene, target || gameObjet, 1, duration);
  });
};

export const convertSecondToHour = (seconds: number) => {
  const m = Math.floor(seconds / 60);
  const s = seconds % 60;
  return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
};

export const getPosCenterObject = (gameObject) => {
  const x =
    gameObject.x - gameObject.displayOriginX + gameObject.displayWidth / 2;
  const y =
    gameObject.y - gameObject.displayOriginY + gameObject.displayHeight / 2;
  return { x, y };
};

export const genArrayPosCenter = (options) => {
  const {
    width,
    padding,
    count,
    y,
    centerWidthPos = 1920,
    originX = 0.5,
  } = options;
  const widthBox = width + padding;
  const startWidth = centerWidthPos - (widthBox * count) / 2 + width * originX;
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push({
      x: startWidth + i * widthBox,
      y,
    });
  }
  return result;
};
